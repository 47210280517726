// store/modules/mensajesRapidos.js
import axios from "axios";

const state = {
  mensajesRapidos: [],
  mensajesRapidosChat: [],
};

const getters = {
  allMensajesRapidos(state) {
    return state.mensajesRapidos;
  }
};

const mutations = {
  SET_MENSAJES_RAPIDOS(state, mensajes) {
    state.mensajesRapidos = mensajes;
  },
  SET_MENSAJES_RAPIDOS_CHAT(state, mensajes) {
    state.mensajesRapidosChat = mensajes;
  },
  ADD_MENSAJE_RAPIDO(state, mensaje) {
    state.mensajesRapidos.push(mensaje);
  },
  UPDATE_MENSAJE_RAPIDO(state, updatedMensaje) {
    const index = state.mensajesRapidos.findIndex(mensaje => mensaje.id === updatedMensaje.id);
    if (index !== -1) {
      state.mensajesRapidos.splice(index, 1, updatedMensaje);
    }
  },
  REMOVE_MENSAJE_RAPIDO(state, mensajeId) {
    state.mensajesRapidos = state.mensajesRapidos.filter(mensaje => mensaje.id !== mensajeId);
  }
};

const actions = {
  async fetchMensajesRapidos({ commit }, params = {}) {
    const config = { params };
    try {
      const { data } = await axios.get("/api/mensajewhatsapprapido", config);
      commit("SET_MENSAJES_RAPIDOS", data); // Asegúrate de ajustar a `data.data` si usas `paginate` en Laravel
    } catch (error) {
      console.error("Error fetching mensajes rapidos:", error);
    }
  },
  async fetchMensajesRapidosChat({ commit }) {
    try {
      const { data } = await axios.get("/api/mensajesrapidos/indexChat");
      commit("SET_MENSAJES_RAPIDOS_CHAT", data); // Asegúrate de ajustar a `data.data` si usas `paginate` en Laravel
    } catch (error) {
      console.error("Error fetching mensajes rapidos:", error);
    }
  },
  
  async agregarMensajeRapido({ commit }, mensaje) {
    try {
      const { data } = await axios.post("/api/mensajewhatsapprapido", mensaje);
      commit("ADD_MENSAJE_RAPIDO", data.data);
    } catch (error) {
      console.error("Error adding mensaje rapido:", error);
    }
  },

  async editarMensajeRapido({ commit }, mensaje) {
    try {
      const { data } = await axios.put(`/api/mensajewhatsapprapido/${mensaje.id}`, mensaje);
      commit("UPDATE_MENSAJE_RAPIDO", data.data);
    } catch (error) {
      console.error("Error updating mensaje rapido:", error);
    }
  },

  async eliminarMensajeRapido({ commit }, mensajeId) {
    try {
      await axios.delete(`/api/mensajewhatsapprapido/${mensajeId}`);
      commit("REMOVE_MENSAJE_RAPIDO", mensajeId);
    } catch (error) {
      console.error("Error deleting mensaje rapido:", error);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
