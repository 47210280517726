<!-- ChatView.vue -->
<template> 
    <v-row>
      <!-- Columna Izquierda: Lista de Sesiones -->
      <v-col cols="4">
        <ListadoSessionesView @sessionSelected="handleSessionSelected" />
      </v-col>
      <!-- Columna Derecha: Chat Principal -->
      <v-col cols="8">
        <div v-if="selectedSessionId">
          <SessionChat :sessionId="selectedSessionId" ref="sessionChat" />
        </div>
        <div v-else class="text-center mt-4">
          Selecciona una sesión para comenzar el chat.
        </div>
      </v-col>
      <!-- Columna Media: Mensajes Rápidos -->
   <!--    <v-col cols="3">
        <QuickMessages :sessionId="selectedSessionId" @messageSent="handleMessageSent" @scrollToLastMessage="scrollToBottomInChat"/>
      </v-col> -->
    </v-row>
</template>

<script setup>
import { ref } from 'vue'
import ListadoSessionesView from '@/components/ListSessionesView.vue'
import SessionChat from '@/components/SessionChat.vue'
/* import QuickMessages from '@/components/QuickMessages.vue' */

const sessionChat = ref(null) // Referencia al componente SessionChat

// Estado para la sesión seleccionada
const selectedSessionId = ref(null)

// Manejar la selección de una sesión desde la lista
const handleSessionSelected = (sessionId) => {
  console.log('Sesión seleccionada:', sessionId)
  selectedSessionId.value = sessionId
}

// Manejar el envío de un mensaje rápido
/* const handleMessageSent = () => {
  if (sessionChat.value && typeof sessionChat.value.fetchMessages === 'function') {
    sessionChat.value.fetchMessages()
  }
} */

/* const scrollToBottomInChat = () => {
  if (sessionChat.value) {
    sessionChat.value.scrollToLastMessage(); // Llama al método en SessionChat
  }
}; */
</script>

<style scoped>
/* Estilos personalizados si es necesario */
</style>
