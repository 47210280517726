import { createStore } from "vuex";
import axios from "axios";
import dashboard from "./modules/dashboard";
import mensajesRapidos from "./modules/mensajesRapidos";
import parametros from "./modules/parametros";
import horarioAtencion from "./modules/horarioAtencion";

export default createStore({
  state: {
    snackbar: {
      show: false,
      message: null,
      status: null,
    },
    user: null,
    session_by_id: [],
    phonenumber: [],
    sessiones: [],
    campanas: [],
    paquetesMaternos: [],
    configuracion: {
      hora_atencion_inicio: "",
      hora_atencion_fin: "",
      habilitado_atencion_asesor: true,
    },
    estados: [],
    estados_callcenter: [],
  },
  getters: {
    menus: (state) => (state.user.rol ? state.user.rol.menu : []),
    isAuthenticated(state) {
      return !!state.user;
    },
    currentUser(state) {
      return { ...state.user };
    },
    configuracion: (state) => state.configuracion,
  },
  mutations: {
    SET_USER(state, data) {
      state.user = data;
    },
    SET_SESSION_BY_ID(state, data) {
      state.session_by_id = data;
    },
    SET_PHONENUMBER(state, data) {
      state.phonenumber = data;
    },
    SET_SESSIONES(state, data) {
      state.sessiones = data;
    },
    SET_CAMPANAS(state, data) {
      state.campanas = data;
    },
    SET_PAQUETES_MATERNO(state, paquetes) {
      state.paquetesMaternos = paquetes;
    },
    SET_CONFIGURACION(state, configuracion) {
      state.configuracion = configuracion;
    },
    SET_ESTADOS(state, estados) {
      state.estados = estados;
    },
    SET_ESTADOS_CALLCENTER(state, estados_callcenter) {
      state.estados_callcenter = estados_callcenter;
    },
    ADD_TEMPORARY_MESSAGE(state, message) {
      state.phonenumber.phonenumber.mensajes.push(message);
    },
    // Actualiza el estado del mensaje
    UPDATE_MESSAGE_STATUS(state, { id, status }) {
      const message = state.phonenumber.phonenumber.mensajes.find(
        (msg) => msg.id === id
      );
      if (message) {
        message.status = status;
      }
    },
    REMOVE_MESSAGE(state, messageId) {
      state.session_by_id.mensajes = state.session_by_id.mensajes.filter(
        (msg) => msg.id !== messageId
      );
    },
    UPDATE_NUEVOS_MENSAJES_SESSION(state, sessionId) {
      // Busca la sesión correspondiente en sessiones
      const session = state.sessiones.find((s) => s.id === sessionId);
      if (session) {
        session.nuevos_mensajes = null;
      }
    },
    ADD_SESSION(state, session) {
      if (session.pinned) {
        // Insertar al principio si está "pinned"
        state.sessiones.unshift(session);
      } else {
        // Encontrar el índice después del último "pinned"
        const pinnedIndex = state.sessiones.findIndex((s) => !s.pinned);
        if (pinnedIndex === -1) {
          // Si no hay "no pinned", insertar al final
          state.sessiones.push(session);
        } else {
          // Insertar después del último "pinned"
          state.sessiones.splice(pinnedIndex, 0, session);
        }
      }
    },
    UPDATE_SESSION(state, { sessionId, latestMensaje }) {
      const session = state.sessiones.find((s) => s.id === sessionId);
      if (session) {
        session.latest_mensaje = latestMensaje;
        session.nuevos_mensajes = (session.nuevos_mensajes || 0) + 1;
      }
    },
    REORDER_SESSIONS(state) {
      const pinnedSessions = state.sessiones.filter((s) => s.pinned === 1);
      const otherSessions = state.sessiones.filter((s) => s.pinned !== 1);

      otherSessions.sort((a, b) => {
        const dateA = new Date(a.mensajes_max_created_at);
        const dateB = new Date(b.mensajes_max_created_at);
        return dateB - dateA;
      });

      state.sessiones = [...pinnedSessions, ...otherSessions];
    },
    UPDATE_SESSION_WITH_LATEST_MESSAGE(state, { sessionId, latestMensaje }) {
      const session = state.sessiones.find((s) => s.id === sessionId);
      if (session) {
        session.latest_mensaje = latestMensaje;
        session.mensajes_max_created_at = latestMensaje.created_at;
      }
    },
    ADD_MESSAGE(state, { chatId, message }) {
      const chat = state.phonenumber.phonenumber;
      console.log(state.phonenumber.phonenumber.id)
      if (chat && chat.id === chatId) {
        chat.mensajes.push(message);
      }
    }
  },
  actions: {
    async login({ dispatch }, credentials) {
      try {
        await axios.post("/login", credentials);
        await dispatch("getUser");
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async register(_, credentials) {
      try {
        await axios.post("/register", credentials);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async logout({ commit }) {
      try {
        await axios.post("/logout");
        commit("SET_USER", null);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async getUser({ commit }) {
      try {
        const { data } = await axios.get("/api/user");
        commit("SET_USER", data);
      } catch (e) {
        commit("SET_USER", null);
        throw new Error(await e.response.data.message);
      }
    },
    async fetchSessionById({ commit }, id) {
      try {
        const { data } = await axios.get(`/api/session/${id}`);
        commit("SET_SESSION_BY_ID", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchPhoneNumber({ commit }, id) {
      try {
        const { data } = await axios.get(`/api/phonenumber/${id}`);
        commit("SET_PHONENUMBER", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async fetchSessiones({ commit }, params) {
      const config = { params };
      try {
        const { data } = await axios.get(`/api/session`, config);
        commit("SET_SESSIONES", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async enviarMensaje(_, { sessionId, mensaje, replyToId }) {
      try {
        // Enviar el mensaje al backend con el reply_to incluido
        await axios.post(`/api/mensaje`, {
          session_id: sessionId,
          mensaje: mensaje,
          reply_to: replyToId, // Incluye reply_to en la solicitud
        });
      } catch (e) {
        console.error("Error al enviar el mensaje:", e);
        throw new Error(
          e.response?.data?.message || "Error al enviar el mensaje"
        );
      }
    },
    async TomarSession({ commit }, sessionId) {
      try {
        // Enviar el mensaje al backend
        await axios.post(`/api/tomarsession`, {
          session_id: sessionId,
        });

        // Commit para editar phonenumber
        commit("UPDATE_NUEVOS_MENSAJES_SESSION", sessionId);
      } catch (e) {
        console.error("Error sending message:", e);
      }
    },

    async pinSession(_, sessionId) {
      try {
        await axios.post(`/api/pinsession`, {
          session_id: sessionId,
        });
      } catch (error) {
        console.error("Error al pinear la sesión:", error);
        throw error; // Opcional: lanzar el error para manejarlo en el componente
      }
    },
    async terminarSession(_, { sessionId }) {
      try {
        // Enviar el mensaje al backend
        await axios.post(`/api/terminarsession`, {
          session_id: sessionId,
        });
      } catch (e) {
        console.error("Error sending message:", e);
      }
    },
    async enviarMensajeRapido(_, { mensajeId, sessionId }) {
      console.log(mensajeId, sessionId);
      try {
        // Enviar el mensaje rápido al backend
        await axios.post(`/api/mensajewhatsapprapido/send/${mensajeId}`, {
          session_id: sessionId,
        });
      } catch (e) {
        console.error("Error sending quick message:", e);
        throw new Error(await e.response.data.message);
      }
    },
    async marcarComoAtendido(_, sessionId) {
      try {
        await axios.post(`/api/marcarAtendido`, { session_id: sessionId });
      } catch (e) {
        console.error("Error al marcar como atendido:", e);
        throw new Error(await e.response.data.message);
      }
    },
    async fetchCampana({ commit }) {
      try {
        const { data } = await axios.get(`/api/campana`);
        commit("SET_CAMPANAS", data);
      } catch (e) {
        throw new Error(await e.response.data.message);
      }
    },
    async agregarCampana(_, file) {
      try {
        // Crear un objeto FormData para enviar el archivo
        const formData = new FormData();
        formData.append("image", file);

        // Enviar la imagen al backend
        await axios.post("/api/campana", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (error) {
        console.error("Error al agregar la imagen:", error);
      }
    },
    async eliminarCampana(_, imageId) {
      try {
        await axios.delete(`/api/campana/${imageId}`);
      } catch (error) {
        console.error("Error al eliminar la imagen:", error);
      }
    },
    async fetchPaquetesMaterno({ commit }) {
      try {
        const { data } = await axios.get(`/api/paquete-materno`);
        commit("SET_PAQUETES_MATERNO", data);
      } catch (e) {
        console.error("Error al obtener los paquetes maternos:", e);
      }
    },
    async agregarPaqueteMaterno(_, file) {
      try {
        // Crear un objeto FormData para enviar el archivo
        const formData = new FormData();
        formData.append("image", file);

        // Enviar la imagen al backend
        await axios.post(`/api/paquete-materno`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (error) {
        console.error("Error al agregar el paquete materno:", error);
      }
    },
    async eliminarPaqueteMaterno(_, paqueteId) {
      try {
        await axios.delete(`/api/paquete-materno/${paqueteId}`);
      } catch (error) {
        console.error("Error al eliminar el paquete materno:", error);
      }
    },
    async fetchConfiguracion({ commit }) {
      try {
        const response = await axios.get(`/api/ajuste`);
        commit("SET_CONFIGURACION", response.data);
      } catch (error) {
        console.error("Error al cargar la configuración:", error);
      }
    },
    async actualizarConfiguracion({ commit }, configuracion) {
      try {
        const ajusteId = 1; // Asumiendo que tienes un solo registro en la tabla de ajustes
        const response = await axios.put(
          `/api/ajuste/${ajusteId}`,
          configuracion
        );
        console.log("Configuración actualizada:", response.data);
        commit("SET_CONFIGURACION", configuracion); // Puedes almacenar la configuración en el estado si lo necesitas
      } catch (error) {
        console.error("Error al actualizar la configuración:", error);
      }
    },
    async fetchEstados({ commit }) {
      try {
        const response = await axios.get(`/api/estado`);
        commit("SET_ESTADOS", response.data);
      } catch (error) {
        console.error("Error al cargar la configuración:", error);
      }
    },
    async fetchEstadosCallcenter({ commit }) {
      try {
        const response = await axios.get(`/api/estado_callcenter`);
        commit("SET_ESTADOS_CALLCENTER", response.data);
      } catch (error) {
        console.error("Error al cargar la configuración:", error);
      }
    },
    async eliminarMensaje({ commit }, messageId) {
      try {
        await axios.post(`/api/mensaje/eliminar`, { message_id: messageId });
        commit("REMOVE_MESSAGE", messageId);
      } catch (error) {
        console.error("Error al eliminar el mensaje:", error);
      }
    },

    async responderMensaje(_, { sessionId, mensaje }) {
      try {
        await axios.post(`/api/mensaje/responder`, {
          session_id: sessionId,
          mensaje: mensaje,
        });
      } catch (error) {
        console.error("Error al responder el mensaje:", error);
      }
    },

    async cambiarEstado({ dispatch }, sessionId) {
      try {
        // Llama a la ruta marcarTerminado
        await axios.post(`/api/marcarTerminado`, { session_id: sessionId });
        await dispatch("fetchPhoneNumber", sessionId); // Refresca los datos de la sesión
      } catch (error) {
        console.error("Error al cambiar el estado general:", error);
        throw new Error(
          error.response?.data?.message || "Error al cambiar estado"
        );
      }
    },

    // Cambiar estado del Callcenter usando marcarAtendido
    async cambiarEstadoCallcenter({ dispatch }, sessionId) {
      try {
        // Llama a la ruta marcarAtendido
        await axios.post(`/api/marcarAtendido`, { session_id: sessionId });
        await dispatch("fetchPhoneNumber", sessionId); // Refresca los datos de la sesión
      } catch (error) {
        console.error("Error al cambiar el estado del Callcenter:", error);
        throw new Error(
          error.response?.data?.message ||
            "Error al cambiar estado de Callcenter"
        );
      }
    },
    async adjuntarArchivo({ commit }, formData) {
      try {
        const response = await axios.post("/api/mensaje_multimedia", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        commit("ADD_TEMPORARY_MESSAGE", response.data.message); // Update state with the new message
      } catch (error) {
        console.error("Error al enviar archivo:", error);
      }
    },
    async borrarMensaje(_, payload) {
      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_API_URL}/api/mensajes`,
          {
            data: payload, // El `whatsapp_id` del mensaje a borrar
          }
        );
        console.log("Mensaje eliminado:", response.data.message);
      } catch (error) {
        console.error("Error al borrar el mensaje:", error);
        throw error;
      }
    },
    async fetchMessages({ commit }, sessionId) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/api/sessions/${sessionId}/messages`
        );
        commit("SET_MESSAGES", response.data);
      } catch (error) {
        console.error("Error al obtener mensajes:", error);
      }
    },
  },
  modules: {
    dashboard,
    mensajesRapidos,
    parametros,
    horarioAtencion,
  },
});
