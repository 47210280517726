// store/modules/dashboard.js

import axios from "axios";

const state = {
  sesionesPorMes: {
    labels_mes: [],
    values: []
  },
  sesionesPorSemana: {
    labels_dia: [],
    values: []
  },
  sesionesPorHora: {
    labels_hora: [],
    values: []
  },
  sesionesPorEstado: {
    labels_estado: [],
    values: []
  }
};

const getters = {
  getSesionesPorMes(state) {
    return state.sesionesPorMes;
  },
  getSesionesPorSemana(state) {
    return state.sesionesPorSemana;
  },
  getSesionesPorHora(state) {
    return state.sesionesPorHora;
  },
  getSesionesPorEstado(state) {
    return state.sesionesPorEstado;
  }
};

const mutations = {
  SET_SESIONES_POR_MES(state, data) {
    state.sesionesPorMes = data;
  },
  SET_SESIONES_POR_SEMANA(state, data) {
    state.sesionesPorSemana = data;
  },
  SET_SESIONES_POR_HORA(state, data) {
    state.sesionesPorHora = data;
  },
  SET_SESIONES_POR_ESTADO(state, data) {
    state.sesionesPorEstado = data;
  }
};

const actions = {
  async fetchSesionesPorMes({ commit }) {
    try {
      const { data } = await axios.get("/api/dashboard/sessionespormes");
      commit("SET_SESIONES_POR_MES", data);
    } catch (error) {
      console.error("Error fetching sesiones por mes:", error);
      throw error;
    }
  },
  async fetchSesionesPorSemana({ commit }) {
    try {
      const { data } = await axios.get("/api/dashboard/sessionespordiasemana");
      commit("SET_SESIONES_POR_SEMANA", data);
    } catch (error) {
      console.error("Error fetching sesiones por semana:", error);
      throw error;
    }
  },
  async fetchSesionesPorHora({ commit }) {
    try {
      const { data } = await axios.get("/api/dashboard/sessionesporhora");
      commit("SET_SESIONES_POR_HORA", data);
    } catch (error) {
      console.error("Error fetching sesiones por hora:", error);
      throw error;
    }
  },
  async fetchSesionesPorEstado({ commit }) {
    try {
      const { data } = await axios.get("/api/dashboard/sessionesporestado");
      commit("SET_SESIONES_POR_ESTADO", data);
    } catch (error) {
      console.error("Error fetching sesiones por estado:", error);
      throw error;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
