<template>
  <v-col>
      <v-card class="ma-0 overflow-auto" max-height="80vh">
          <v-card-title>Mensajes Rápidos</v-card-title>
          <v-card-subtitle>Seleccione su mensaje rápido</v-card-subtitle>

          <!-- Campo de búsqueda -->
          <v-text-field v-model="searchQuery" label="Buscar mensaje" prepend-inner-icon="mdi-magnify" outlined dense
              class="mx-4 mt-3"></v-text-field>

          <v-card-text>
              <!-- Mostrar los mensajes filtrados -->
              <v-card v-for="mensaje in filteredMensajesRapidos" :key="mensaje.id" variant="tonal"
                  class="mx-auto mt-3" color="green">
                  <v-card-title>{{ mensaje.titulo }}</v-card-title>
                  <v-card-text>
                      {{ mensaje.descripcion }}
                      <v-icon v-if="mensaje.status === 'sending'" small class="ml-2">mdi-clock-outline</v-icon>
                      <v-icon v-if="mensaje.status === 'error'" small class="ml-2 text-error">mdi-alert-circle</v-icon>
                  </v-card-text>
                  <v-card-actions>
                      <v-btn variant="outlined" @click="enviarMensajeRapido(mensaje.id)" :disabled="estadoId === 2">
                          Enviar
                      </v-btn>
                  </v-card-actions>
              </v-card>

              <!-- Mensaje de "No hay resultados" cuando no se encuentran mensajes -->
              <div v-if="filteredMensajesRapidos.length === 0" class="text-center my-4">
                  No se encontraron mensajes.
              </div>
          </v-card-text>
      </v-card>
  </v-col>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits, nextTick } from 'vue';
import { useStore } from 'vuex';


const store = useStore();

const emit = defineEmits(['scrollToLastMessage']); // Emitimos para que el scroll funcione en el componente principal

const searchQuery = ref(''); // Campo para la búsqueda

// Obtener los mensajes rápidos desde el módulo 'mensajesRapidos' en Vuex
const mensajesRapidos = computed(() => store.state.mensajesRapidos.mensajesRapidosChat);

// Computed para filtrar los mensajes basados en la búsqueda
const filteredMensajesRapidos = computed(() => {
  return mensajesRapidos.value.map(mensaje => ({
      ...mensaje,
      status: mensaje.status || null
  })).filter(mensaje =>
      mensaje.titulo.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      mensaje.descripcion.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

// Computed para obtener el estado_id desde Vuex
const estadoId = computed(() => store.state.session_by_id?.estado_callcenter_id || 0);

// Método para enviar un mensaje rápido
const enviarMensajeRapido = async (mensajeId) => {
  const sessionId = store.state.phonenumber.session.id;

  // Buscar el mensaje rápido en la lista de mensajes rápidos
  const mensaje = mensajesRapidos.value.find(msg => msg.id === mensajeId);

  // Crear un mensaje temporal con estado "sending"
  const tempMessage = {
      id: Date.now(), // ID temporal
      mensaje: mensaje.descripcion,
      tipo_envio_mensaje_id: 1, // Ajusta según tu estructura
      tipo_mensaje_id: 1,
      created_at: new Date(),
      status: 'sending'
  };

  // Agregar el mensaje temporalmente al chat de la sesión actual
  store.commit('ADD_TEMPORARY_MESSAGE', tempMessage);

  // Emitir el desplazamiento al último mensaje inmediatamente después de añadirlo temporalmente
  await nextTick();
  emit('scrollToLastMessage');

  // Intentar enviar el mensaje al backend
  try {
     
      const session = store.state.sessiones.find((s) => s.id === sessionId);
      if (session) {
        // Actualizar el `latest_mensaje` y la fecha de creación
        session.latest_mensaje = tempMessage;
        session.mensajes_max_created_at = tempMessage.created_at;

        // Reordenar las sesiones por la fecha del último mensaje
        store.commit('REORDER_SESSIONS');
      }
      await store.dispatch("enviarMensajeRapido", { mensajeId, sessionId });
      // Actualizar el estado a "sent" si el envío es exitoso
      store.commit('UPDATE_MESSAGE_STATUS', { id: tempMessage.id, status: 'sent' });
  } catch (error) {
      console.error("Error al enviar el mensaje rápido:", error);

      // Actualizar el estado a "error" si falla el envío
      store.commit('UPDATE_MESSAGE_STATUS', { id: tempMessage.id, status: 'error' });
  }

  // Emitir el desplazamiento al último mensaje nuevamente para asegurar el scroll después de la respuest
};

// Cargar mensajes rápidos al montar el componente
onMounted(() => {
  store.dispatch("mensajesRapidos/fetchMensajesRapidosChat");
});
</script>