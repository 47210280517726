// store/modules/parametros.js
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    rangoDiasCita: 0,
  },
  mutations: {
    SET_RANGO_DIAS_CITA(state, rango) {
      state.rangoDiasCita = rango;
    },
  },
  actions: {
    async fetchRangoDiasCita({ commit }) {
      try {
        const response = await axios.get('/api/parametro');
        const parametro = response.data.data.find(param => param.id); // Ajusta según la estructura de tu respuesta
        commit('SET_RANGO_DIAS_CITA', parametro.rango_fecha_citas);
      } catch (error) {
        console.error('Error fetching rangoDiasCita:', error);
      }
    },
    async storeRangoDiasCita({ commit }, rango) {
      try {
        const response = await axios.patch(`/api/parametro/1`, { rango_fecha_citas: rango });
        commit('SET_RANGO_DIAS_CITA', rango);
        return response.data;
      } catch (error) {
        console.error('Error saving rangoDiasCita:', error);
        throw error;
      }
    },
  },
  getters: {
    rangoDiasCita: state => state.rangoDiasCita,
  },
};
