<template>
  <v-card outlined class="mt-0">
    <v-card-title>Configuración de Atención</v-card-title>
    <v-card-text>
      <v-form @submit.prevent="guardarConfiguracion">
     <!--    <v-row>
          <v-col cols="12" sm="4">
            <v-switch color="green" v-model="atencionConAsesor" label="Habilitar atenciones con asesor"></v-switch>
          </v-col>
        </v-row> -->
        <v-row v-for="(dia, index) in diasSemana" :key="index">
          <v-col cols="12" sm="4">
            <v-switch v-model="dia.habilitado" :label="dia.nombre" color="green" hide-details></v-switch>
          </v-col>
          <v-col cols="12" sm="4" v-if="dia.habilitado">
            <v-text-field v-model="dia.horarioInicio" label="Hora de Inicio" type="time" format="24hr" hide-details></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" v-if="dia.habilitado">
            <v-text-field v-model="dia.horarioFin" label="Hora de Fin" type="time" format="24hr" hide-details></v-text-field>
          </v-col>
        </v-row>
        <v-btn type="submit" color="green" class="mt-4">Guardar Configuración</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, onMounted} from 'vue';
import { useStore } from 'vuex';

const store = useStore();

/* const atencionConAsesor = ref(false); */

// Inicializar los días de la semana con horario y habilitado por defecto
const diasSemana = ref([
  { nombre: 'Lunes', habilitado: false, horarioInicio: '', horarioFin: '' },
  { nombre: 'Martes', habilitado: false, horarioInicio: '', horarioFin: '' },
  { nombre: 'Miércoles', habilitado: false, horarioInicio: '', horarioFin: '' },
  { nombre: 'Jueves', habilitado: false, horarioInicio: '', horarioFin: '' },
  { nombre: 'Viernes', habilitado: false, horarioInicio: '', horarioFin: '' },
  { nombre: 'Sábado', habilitado: false, horarioInicio: '', horarioFin: '' },
  { nombre: 'Domingo', habilitado: false, horarioInicio: '', horarioFin: '' },
]);

// Cargar configuración al montar el componente
onMounted(async () => {
  // Cargar los días de atención y sus horarios desde el backend
  await store.dispatch('horarioAtencion/fetchDiasAtencion');
  const diasAtencion = store.getters['horarioAtencion/diasAtencion'];

  diasSemana.value.forEach((dia) => {
    // Encontrar los datos del día en la respuesta de la API
    const diaData = diasAtencion.find(d => d.dia.toLowerCase() === dia.nombre.toLowerCase());

    if (diaData && diaData.horarios && diaData.horarios.length > 0) {
      // Si hay horarios, habilitar el switch y asignar los horarios
      dia.habilitado = true;
      dia.horarioInicio = diaData.horarios[0].hora_inicio;
      dia.horarioFin = diaData.horarios[0].hora_fin;
    } else {
      // Si no hay horarios, el switch se mantiene apagado y sin horas asignadas
      dia.habilitado = false;
      dia.horarioInicio = '';
      dia.horarioFin = '';
    }
  });
});

const guardarConfiguracion = () => {
  const diasAtencionHabilitados = diasSemana.value
    .filter(dia => dia.habilitado)
    .map(dia => ({
      dia: dia.nombre.toLowerCase(),
      hora_inicio: dia.horarioInicio,
      hora_fin: dia.horarioFin,
    }));

  const diasAtencionDeshabilitados = diasSemana.value
    .filter(dia => !dia.habilitado)
    .map(dia => dia.nombre.toLowerCase());

  // Guardar horarios habilitados y eliminar los deshabilitados
  store.dispatch('horarioAtencion/guardarDiasAtencion', { diasAtencionHabilitados, diasAtencionDeshabilitados });
};
</script>

<style scoped>
/* Puedes agregar estilos personalizados aquí si lo necesitas */
</style>
