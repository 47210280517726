<template>
  <v-container>
    <v-row>
      <!-- Primer gráfico: Sesiones por mes -->
      <v-col cols="12" md="4">
        <v-card outlined class="mt-0">
          <v-card-title>Sesiones</v-card-title>
          <v-card-subtitle>Por Mes</v-card-subtitle>
          <v-card-text class="text-center">
            <div id="chartdiv_mes"></div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Segundo gráfico: Sesiones por semana -->
      <v-col cols="12" md="4">
        <v-card outlined class="mt-0">
          <v-card-title>Sesiones</v-card-title>
          <v-card-subtitle>Por Semana</v-card-subtitle>
          <v-card-text class="text-center">
            <div id="chartdiv_semana"></div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Tercer gráfico: Sesiones por hora -->
      <v-col cols="12" md="4">
        <v-card outlined class="mt-0">
          <v-card-title>Sesiones</v-card-title>
          <v-card-subtitle>Por Hora</v-card-subtitle>
          <v-card-text class="text-center">
            <div id="chartdiv_hora"></div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Gráfico adicional: Sesiones por estado (Componente separado) -->
    <v-row>
      <v-col cols="12" md="12">
        <pie-chart-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PieChartView from "./PieChartView.vue";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { computed, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    PieChartView,
  },
  setup() {
    const store = useStore();

    // Llamada a la acción para cargar los datos de sesiones por mes, semana y hora
    store.dispatch("dashboard/fetchSesionesPorMes");
    store.dispatch("dashboard/fetchSesionesPorSemana");
    store.dispatch("dashboard/fetchSesionesPorHora");

    // Obtener los datos de sesiones desde Vuex
    const sesionesPorMes = computed(() => store.getters["dashboard/getSesionesPorMes"]);
    const sesionesPorSemana = computed(() => store.getters["dashboard/getSesionesPorSemana"]);
    const sesionesPorHora = computed(() => store.getters["dashboard/getSesionesPorHora"]);

    let rootMes, rootSemana, rootHora;
    let seriesMes, xAxisMes;
    let seriesSemana, xAxisSemana;
    let seriesHora, xAxisHora;

    // Crear el tema personalizado
    const myTheme = (root) => {
      const theme = am5.Theme.new(root);
      theme.rule("AxisLabel", ["minor"]).setAll({
        dy: 1,
      });
      return theme;
    };

    // Funciones para crear gráficos
    const createChartMes = () => {
      if (rootMes) {
        rootMes.dispose();
      }
      rootMes = am5.Root.new("chartdiv_mes");

      // Aplicar temas
      rootMes.setThemes([am5themes_Animated.new(rootMes), myTheme(rootMes), am5themes_Responsive.new(rootMes)]);

      let chart = rootMes.container.children.push(
        am5xy.XYChart.new(rootMes, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
        })
      );

      let cursor = chart.set("cursor", am5xy.XYCursor.new(rootMes, { behavior: "zoomX" }));
      cursor.lineY.set("visible", false);

      xAxisMes = chart.xAxes.push(
        am5xy.CategoryAxis.new(rootMes, {
          categoryField: "month",
          renderer: am5xy.AxisRendererX.new(rootMes, { minGridDistance: 30, minorGridEnabled: true, minorLabelsEnabled: true }),
          tooltip: am5.Tooltip.new(rootMes, {}),
        })
      );

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(rootMes, {
          renderer: am5xy.AxisRendererY.new(rootMes, {}),
        })
      );

      seriesMes = chart.series.push(
        am5xy.ColumnSeries.new(rootMes, {
          name: "Sesiones",
          xAxis: xAxisMes,
          yAxis: yAxis,
          valueYField: "value",
          categoryXField: "month",
          tooltip: am5.Tooltip.new(rootMes, {
            labelText: "Mes: {categoryX}\nSesiones: {valueY}",
          }),
        })
      );

      seriesMes.columns.template.setAll({ strokeOpacity: 0 });

      // Añadir scrollbar horizontal
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(rootMes, {
          orientation: "horizontal",
        })
      );

      updateChartDataMes();
    };

    const createChartSemana = () => {
      if (rootSemana) {
        rootSemana.dispose();
      }
      rootSemana = am5.Root.new("chartdiv_semana");

      // Aplicar temas
      rootSemana.setThemes([am5themes_Animated.new(rootSemana), myTheme(rootSemana), am5themes_Responsive.new(rootSemana)]);

      let chart = rootSemana.container.children.push(
        am5xy.XYChart.new(rootSemana, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
        })
      );

      let cursor = chart.set("cursor", am5xy.XYCursor.new(rootSemana, { behavior: "zoomX" }));
      cursor.lineY.set("visible", false);

      xAxisSemana = chart.xAxes.push(
        am5xy.CategoryAxis.new(rootSemana, {
          categoryField: "day",
          renderer: am5xy.AxisRendererX.new(rootSemana, { minGridDistance: 30, minorGridEnabled: true, minorLabelsEnabled: true }),
          tooltip: am5.Tooltip.new(rootSemana, {}),
        })
      );

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(rootSemana, {
          renderer: am5xy.AxisRendererY.new(rootSemana, {}),
        })
      );

      seriesSemana = chart.series.push(
        am5xy.ColumnSeries.new(rootSemana, {
          name: "Sesiones",
          xAxis: xAxisSemana,
          yAxis: yAxis,
          valueYField: "value",
          categoryXField: "day",
          tooltip: am5.Tooltip.new(rootSemana, {
            labelText: "Día: {categoryX}\nSesiones: {valueY}",
          }),
        })
      );

      seriesSemana.columns.template.setAll({ strokeOpacity: 0 });

      // Añadir scrollbar horizontal
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(rootSemana, {
          orientation: "horizontal",
        })
      );

      updateChartDataSemana();
    };

    const createChartHora = () => {
      if (rootHora) {
        rootHora.dispose();
      }
      rootHora = am5.Root.new("chartdiv_hora");

      // Aplicar temas
      rootHora.setThemes([am5themes_Animated.new(rootHora), myTheme(rootHora), am5themes_Responsive.new(rootHora)]);

      let chart = rootHora.container.children.push(
        am5xy.XYChart.new(rootHora, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
        })
      );

      let cursor = chart.set("cursor", am5xy.XYCursor.new(rootHora, { behavior: "zoomX" }));
      cursor.lineY.set("visible", false);

      xAxisHora = chart.xAxes.push(
        am5xy.CategoryAxis.new(rootHora, {
          categoryField: "hour",
          renderer: am5xy.AxisRendererX.new(rootHora, { minGridDistance: 30, minorGridEnabled: true, minorLabelsEnabled: true }),
          tooltip: am5.Tooltip.new(rootHora, {}),
        })
      );

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(rootHora, {
          renderer: am5xy.AxisRendererY.new(rootHora, {}),
        })
      );

      seriesHora = chart.series.push(
        am5xy.ColumnSeries.new(rootHora, {
          name: "Sesiones",
          xAxis: xAxisHora,
          yAxis: yAxis,
          valueYField: "value",
          categoryXField: "hour",
          tooltip: am5.Tooltip.new(rootHora, {
            labelText: "Hora: {categoryX}\nSesiones: {valueY}",
          }),
        })
      );

      seriesHora.columns.template.setAll({ strokeOpacity: 0 });

      // Añadir scrollbar horizontal
      chart.set(
        "scrollbarX",
        am5.Scrollbar.new(rootHora, {
          orientation: "horizontal",
        })
      );

      updateChartDataHora();
    };

    // Actualizar los datos de los gráficos
    const updateChartDataMes = () => {
      if (xAxisMes && seriesMes && sesionesPorMes.value.labels_mes.length > 0) {
        const data = sesionesPorMes.value.labels_mes.map((month, index) => ({
          month: month,
          value: sesionesPorMes.value.value[index],
        }));

        xAxisMes.data.setAll(data);
        seriesMes.data.setAll(data);
      }
    };

    const updateChartDataSemana = () => {
      if (xAxisSemana && seriesSemana && sesionesPorSemana.value.labels_dia.length > 0) {
        const data = sesionesPorSemana.value.labels_dia.map((day, index) => ({
          day: day,
          value: sesionesPorSemana.value.value[index],
        }));

        xAxisSemana.data.setAll(data);
        seriesSemana.data.setAll(data);
      }
    };

    const updateChartDataHora = () => {
      if (xAxisHora && seriesHora && sesionesPorHora.value.labels_hora.length > 0) {
        const data = sesionesPorHora.value.labels_hora.map((hour, index) => ({
          hour: hour,
          value: sesionesPorHora.value.value[index],
        }));

        xAxisHora.data.setAll(data);
        seriesHora.data.setAll(data);
      }
    };

    onMounted(() => {
      // Los gráficos se crearán cuando los datos estén disponibles
    });

    // Observar los datos y crear los gráficos cuando estén disponibles
    watch(sesionesPorMes, (newValue) => {
      if (newValue.labels_mes.length > 0) {
        createChartMes();
      }
    });

    watch(sesionesPorSemana, (newValue) => {
      if (newValue.labels_dia.length > 0) {
        createChartSemana();
      }
    });

    watch(sesionesPorHora, (newValue) => {
      if (newValue.labels_hora.length > 0) {
        createChartHora();
      }
    });

    // Limpiar gráficos cuando se desmonte el componente
    onUnmounted(() => {
      if (rootMes) rootMes.dispose();
      if (rootSemana) rootSemana.dispose();
      if (rootHora) rootHora.dispose();
    });

    return { sesionesPorMes, sesionesPorSemana, sesionesPorHora };
  },
};
</script>

<style scoped>
#chartdiv_mes,
#chartdiv_semana,
#chartdiv_hora {
  height: 300px;
}
</style>
