<template>
  <div class="d-flex align-center justify-center" style="height: 100vh">
    <v-sheet width="550" class="mx-auto">
      <v-card class="mt-0 justify-center" elevation="10">
        <v-card-title class="text-center justify-center">WHATSAPP CLINICA SJD</v-card-title>
        <v-card-text>
          <v-form @submit.prevent="login">
            <v-text-field v-model="form.email" label="EMAIL" type="email" required
          ></v-text-field>
            <v-text-field v-model="form.password" label="PASSWORD" required
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" counter
              @click:append="showPassword = !showPassword" ></v-text-field>
            <v-btn class="mt-4" type="submit" color="blue-darken-4" block>LOGIN</v-btn>
          </v-form>
          <!--  <div class="text-center mt-3">
            <v-btn text>OLVIDO SU CONTRASEÑA ?</v-btn>
            <span class="mx-2">|</span>
            <v-btn text>REGISTRAR</v-btn>
          </div> -->
        </v-card-text>
      </v-card>  
        <v-snackbar transition="scale-transition" top :color="snackbar.status" v-model="snackbar.show" timeout="3500">
          {{ snackbar.message }}
        </v-snackbar>
    </v-sheet>
  </div>
</template>
<script>
export default {
  data() {
    return {
         form: {
           email: '',
           password: ''
         },
 /*      form: {
        email: '',
        password: ''
      }, */
      showPassword: false,
      snackbar: {
        show: false,
        status: null,
        message: null
      }
    };
  },
  methods: {
    async login() {
      /* const isValid = await this.$refs.observer.validate() */
      /*   if (!isValid) return */
      this.$store.commit('SET_LOADING', true)
      try {
        await this.$store.dispatch('login', this.form)
        await this.$router.push('/')
    /*     this.$store.commit('SET_LOADING', false)
        this.$store.commit('SHOW_SUCCESS_SNACKBAR', this.$t('messages.success.login')) */
      } catch (e) {
        this.snackbar.status = 'error'
        this.snackbar.message = e.message
        this.snackbar.show = true
        this.$store.commit('SET_LOADING', false)
      }
    }
  }
};
</script>

<style>
.v-card {
  margin-top: 100px;
}
</style>