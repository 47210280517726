// store/modules/horarioAtencion.js
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    diasAtencion: [],
  },
  mutations: {
    SET_DIAS_ATENCION(state, dias) {
      state.diasAtencion = dias;
    },
  },
  actions: {
    async fetchDiasAtencion({ commit }) {
      try {
        const response = await axios.get('/api/diaatencion');
        commit('SET_DIAS_ATENCION', response.data.data); // Ajuste para estructura de respuesta
      } catch (error) {
        console.error('Error fetching días de atención:', error);
      }
    },
    async guardarDiasAtencion(_, { diasAtencionHabilitados, diasAtencionDeshabilitados }) {
      try {
        // Guardar los días habilitados
        if (diasAtencionHabilitados.length > 0) {
          await axios.post('/api/horarioatencion', diasAtencionHabilitados);
        }

        // Eliminar los días deshabilitados
        if (diasAtencionDeshabilitados.length > 0) {
          await axios.post('/api/horarioatencion/delete', { dias: diasAtencionDeshabilitados });
        }

        // Actualizar el estado local (fetch nuevamente para asegurarse de que esté sincronizado)
        await this.dispatch('horarioAtencion/fetchDiasAtencion');
      } catch (error) {
        console.error('Error guardando los días de atención:', error);
      }
    },
  },
  getters: {
    diasAtencion: (state) => state.diasAtencion,
  },
};
