<template>
  <v-card outlined class="mt-0">
    <v-card-title>
      LISTADO DE MENSAJES RÁPIDOS
      <!-- Botón para abrir el modal de creación de un nuevo mensaje rápido -->
      <v-btn class="ml-5" color="green" @click="showCreateModal = true">
        <v-icon left>mdi-plus</v-icon>
        Agregar Mensaje Rápido
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-data-table-server :headers="headers" :items="mensajesRapidos.data" v-if="mensajesRapidos.data"
        :loading="isLoading" v-model:options="options" :items-length="totalMensajes" density="compact"
        class="elevation-0" :items-per-page-options="itemsPorPagina">

        <!-- Mostrar el título del mensaje rápido -->
        <template v-slot:[`item.titulo`]="{ item }">
          {{ item.titulo }}
        </template>

        <!-- Mostrar la descripción del mensaje rápido -->
        <template v-slot:[`item.descripcion`]="{ item }">
          {{ item.descripcion }}
        </template>

        <!-- Acciones: Editar y Eliminar mensaje rápido -->
        <template v-slot:[`item.acciones`]="{ item }">
          <v-btn icon color="orange" @click="openEditModal(item)" density="compact" variant="text">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon color="red" @click="openDeleteModal(item.id)" density="compact" variant="text">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table-server>
    </v-card-text>

    <!-- Modal de Confirmación de Eliminación -->
    <v-dialog v-model="showDeleteModal" max-width="500px">
      <v-card>
        <v-card-title class="headline">¿Desea eliminar este mensaje rápido?</v-card-title>
        <v-card-text>Esta acción no se puede deshacer.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" @click="confirmDelete">Eliminar</v-btn>
          <v-btn @click="showDeleteModal = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de Edición de Mensaje Rápido -->
    <v-dialog v-model="showEditModal" max-width="600px">
      <v-card>
        <v-card-title class="headline">Editar Mensaje Rápido</v-card-title>
        <v-card-text>
          <!-- Campo para editar el título del mensaje rápido -->
          <v-text-field v-model="editedMessage.titulo" label="Título" outlined class="mb-4"></v-text-field>
          
          <!-- Campo para editar la descripción del mensaje rápido con el selector de emojis -->
          <v-menu
            v-model="emojiMenuEdit"
            offset-y
            max-width="250"
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-model="editedMessage.descripcion"
                label="Descripción"
                outlined
                prepend-inner-icon="mdi-emoticon-outline"
                v-bind="props"
                @click:prepend-inner.stop="emojiMenuEdit = !emojiMenuEdit"
              />
            </template>

            <v-card>
              <v-container>
                <v-row dense>
                  <v-col
                    v-for="(emoji, index) in emojis"
                    :key="index"
                    cols="2"
                    class="text-center pa-1"
                  >
                    <v-btn
                      text
                      @click="agregarEmoji(emoji, 'edited')"
                    >
                      {{ emoji }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="saveEdit">Guardar</v-btn>
          <v-btn text @click="showEditModal = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal de Creación de Nuevo Mensaje Rápido -->
    <v-dialog v-model="showCreateModal" max-width="600px">
      <v-card>
        <v-card-title class="headline">Agregar Nuevo Mensaje Rápido</v-card-title>
        <v-card-text>
          <!-- Campo para crear el título del mensaje rápido -->
          <v-text-field v-model="newMessage.titulo" label="Título" outlined class="mb-4"></v-text-field>
          
          <!-- Campo para crear la descripción del mensaje rápido con el selector de emojis -->
          <v-menu
            v-model="emojiMenuNew"
            offset-y
            max-width="250"
          >
            <template v-slot:activator="{ props }">
              <v-text-field
                v-model="newMessage.descripcion"
                label="Descripción"
                outlined
                prepend-inner-icon="mdi-emoticon-outline"
                v-bind="props"
                @click:prepend-inner.stop="emojiMenuNew = !emojiMenuNew"
              />
            </template>

            <v-card>
              <v-container>
                <v-row dense>
                  <v-col
                    v-for="(emoji, index) in emojis"
                    :key="index"
                    cols="2"
                    class="text-center pa-1"
                  >
                    <v-btn
                      text
                      @click="agregarEmoji(emoji, 'new')"
                    >
                      {{ emoji }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-menu>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="saveNewMessage">Agregar</v-btn>
          <v-btn text @click="showCreateModal = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

// Estado reactivo para modales y datos de edición y creación
const showDeleteModal = ref(false);
const showEditModal = ref(false);
const showCreateModal = ref(false); // Nuevo modal de creación
const emojiMenuEdit = ref(false);
const emojiMenuNew = ref(false);
const messageIdToDelete = ref(null);
const editedMessage = ref({ id: null, titulo: '', descripcion: '' });
const newMessage = ref({ titulo: '', descripcion: '' }); // Datos del nuevo mensaje

// Estado para manejar la carga y la tabla
const isLoading = ref(false);
const options = ref({});
const params = ref({
  querySelectedDates: [],
  queryNumeroCelular: ''
});
const itemsPorPagina = ref([
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: 100, title: '100' }
]);

// Computed para acceder a los mensajes rápidos desde Vuex
const mensajesRapidos = computed(() => store.state.mensajesRapidos.mensajesRapidos);
const totalMensajes = computed(() => mensajesRapidos.value.total);

// Lista de emojis disponibles para agregar
const emojis = [
  '😀', '😁', '😂', '🤣', '😊', '😍', '😎', '🤩', '🤔', '😢', '😡', '👍', '🙏',
  '👀', '🤝', '✨', '🤒', '😷', '👋🏻', '🚑', '🚨', '📲', '💰', '💊', '🩺',
  '🌡️', '🩸', '🩻', '🧬', '🔬', '📩', '📅', '📝', '☢️', '🕐', '☠️', '✍🏻', '👩🏻‍⚕️', '🏥'
];

// Definir los headers para el data table
const headers = [
  { title: 'Título', align: 'center', key: 'titulo', sortable: false },
  { title: 'Descripción', align: 'center', key: 'descripcion', sortable: false },
  { title: 'Acciones', align: 'center', key: 'acciones', sortable: false }
];

// Función para abrir el modal de confirmación de eliminación
const openDeleteModal = (id) => {
  messageIdToDelete.value = id;
  showDeleteModal.value = true;
};

// Función para confirmar la eliminación
const confirmDelete = async () => {
  try {
    await store.dispatch("mensajesRapidos/eliminarMensajeRapido", messageIdToDelete.value);
    showDeleteModal.value = false; // Cerrar el modal
    cargarData(); // Recargar la lista de mensajes rápidos
  } catch (error) {
    console.error('Error al eliminar mensaje rápido:', error);
  }
};

// Función para abrir el modal de edición con datos
const openEditModal = (mensaje) => {
  editedMessage.value = { ...mensaje }; // Copiar los datos del mensaje
  showEditModal.value = true;
};

// Función para agregar un emoji al campo correspondiente (nuevo o editado)
const agregarEmoji = (emoji, type) => {
  if (type === 'new') {
    newMessage.value.descripcion += emoji;
    emojiMenuNew.value = false; // Cerrar el menú de emojis
  } else if (type === 'edited') {
    editedMessage.value.descripcion += emoji;
    emojiMenuEdit.value = false; // Cerrar el menú de emojis
  }
};

// Función para guardar los cambios al editar un mensaje rápido
const saveEdit = async () => {
  try {
    await store.dispatch("mensajesRapidos/editarMensajeRapido", editedMessage.value);
    showEditModal.value = false; // Cerrar el modal
    cargarData(); // Recargar la lista de mensajes rápidos
  } catch (error) {
    console.error('Error al guardar cambios en el mensaje rápido:', error);
  }
};

// Función para agregar un nuevo mensaje rápido
const saveNewMessage = async () => {
  try {
    await store.dispatch("mensajesRapidos/agregarMensajeRapido", newMessage.value);
    showCreateModal.value = false; // Cerrar el modal
    newMessage.value = { titulo: '', descripcion: '' }; // Limpiar el formulario
    cargarData(); // Recargar la lista de mensajes rápidos
  } catch (error) {
    console.error('Error al agregar el nuevo mensaje rápido:', error);
  }
};

// Función para cargar los mensajes rápidos y aplicar los parámetros y opciones
const cargarData = async () => {
  isLoading.value = true;
  params.value = {
    ...params.value,
    ...options.value,
  };
  try {
    await store.dispatch('mensajesRapidos/fetchMensajesRapidos', params.value);
  } catch (e) {
    console.error('Error al cargar los mensajes rápidos:', e.message);
  } finally {
    isLoading.value = false;
  }
};

// Observadores para recargar datos cuando cambian los parámetros de búsqueda
watch(() => params.value.querySelectedDates, cargarData);
watch(() => params.value.queryNumeroCelular, cargarData);
watch(() => options.value, cargarData);

// Lifecycle hook para cargar datos al montar el componente
onMounted(() => {
  cargarData();
});
</script>
