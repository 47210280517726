<template>
  <v-card outlined class="mt-0" flat>
    <v-card-text>
      <v-card class="mx-auto mt-0">
        <!-- Toolbar Superior -->
        <v-toolbar color="indigo-darken-3">
          <v-toolbar-title>Chats</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- Campo de Búsqueda -->
          <v-text-field v-model="params.queryNumeroCelular" append-icon="mdi-magnify" label="Buscar" single-line
            hide-details @keyup.enter="cargarData" class="mx-4"></v-text-field>
        </v-toolbar>

        <!-- Indicador de Carga -->
        <v-progress-linear v-if="isLoading" indeterminate color="primary"></v-progress-linear>

        <!-- Virtual Scroll para la Lista de Chats -->
        <v-virtual-scroll v-else :items="formattedItems" height="75vh" item-height="70" class="pa-2">
          <template v-slot:default="{ item }">
            <v-list-item @click="selectSession(item)" class="cursor-pointer">
              <!-- Avatar Prepend -->
              <template v-slot:prepend>
                <v-avatar color="grey-lighten-1">
                  <v-icon color="white" size="large">mdi-account</v-icon>
                </v-avatar>
              </template>

              <!-- Contenido del List Item -->
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
              </v-list-item-content>

              <!-- Botón Append con Indicador de Pinneado -->
              <template v-slot:append>
                <v-badge v-if="item.nuevos_mensajes > 0 && item.id != currentSessionId" color="green"
                  :content="item.nuevos_mensajes" inline></v-badge>
                <v-icon v-if="item.pinned === 1" color="green">mdi-pin</v-icon>
              </template>
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>

        <!-- Mensaje cuando no hay chats -->
        <div v-if="!isLoading && formattedItems.length === 0" class="text-center mt-4">
          No se encontraron mensajes.
        </div>

        <!-- Mensaje de Error -->
        <div v-if="error" class="text-center mt-4 text-red-500">
          {{ error }}
        </div>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, computed, onMounted, watch, defineEmits } from 'vue'
import { useStore } from 'vuex'

// Definir los eventos que el componente puede emitir
const emit = defineEmits(['sessionSelected'])

const store = useStore()

// Estados reactivos
const params = ref({
  queryNumeroCelular: ''
})
const isLoading = ref(false)
const error = ref(null)
const currentSessionId = computed(() => store.state.phonenumber?.session?.id || null);

// Función para cargar datos desde el backend
const cargarData = async () => {
  isLoading.value = true
  error.value = null
  try {
    await store.dispatch('fetchSessiones', params.value)
  } catch (e) {
    error.value = e.message || 'Error al cargar las sesiones.'
  } finally {
    isLoading.value = false
  }
}
const formatDate = (date) => {
  if (!date) return 'Sin fecha';
  const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
  return new Date(date).toLocaleDateString('es-ES', options);
};

// Watchers para los parámetros de búsqueda
watch(() => params.value.queryNumeroCelular, cargarData)

// Computed para obtener las sesiones desde el store
const sessiones = computed(() => store.state.sessiones || [])

// Función auxiliar para generar el subtitle basado en tipo_envio_mensaje_id
const generateSubtitle = (session) => {
  if (!session.latest_mensaje) {
    return `Hola`;
  }

  const { tipo_envio_mensaje_id, mensaje, created_at } = session.latest_mensaje;
  const prefix = tipo_envio_mensaje_id == 1 ? 'Tu: ' : '';
  const formattedDate = formatDate(created_at);

  return `<span class="text-primary">${prefix}</span> ${mensaje} <br><small class="text-secondary">${formattedDate}</small>`;
};


// Computed para formatear los ítems para el virtual scroll
const formattedItems = computed(() => {
  if (!sessiones.value) return []

  return sessiones.value.map(session => {

    return {
      id: session.id, // ID único de la sesión
      title: session.phonenumber.phone_number, // Número de teléfono del contacto
      subtitle: generateSubtitle(session),
      sessionId: session.id, // ID de la sesión
      pinned: session.pinned === 1 ? 1 : 0, // Indicador de pinneado
      nuevos_mensajes: session.nuevos_mensajes, // Indicador de pinneado
      latest_mensaje_created_at: session.mensajes_max_created_at, // Fecha del último mensaje
    }
  })
})

// Función para emitir el evento de selección de sesión
const selectSession = (item) => {
  emit('sessionSelected', item.sessionId)
  store.dispatch('TomarSession', item.sessionId)
}

// Cargar datos al montar el componente
onMounted(async () => {
  await cargarData();

  // Escuchar el evento `message.sent`
  window.Echo.channel('message-sent').listen('.message.sent', (data) => {
    const { sessionId, latestMensaje } = data;
    console.log('mensaje enviado', sessionId)
    // Buscar la sesión en el store
    const session = store.state.sessiones.find((s) => s.id === sessionId);

    if (session) {
      // Actualizar el `latest_mensaje` y la fecha de creación
      session.latest_mensaje = latestMensaje;
      session.mensajes_max_created_at = latestMensaje.created_at;

      // Actualizar el contador de nuevos mensajes basado en el tipo de mensaje
      if (latestMensaje.tipo_envio_mensaje_id === 1) { // Si el mensaje es saliente
        session.nuevos_mensajes = 0; // Eliminar el badge
      } else { // Si el mensaje es entrante
        session.nuevos_mensajes = (session.nuevos_mensajes || 0) + 1; // Incrementar el contador
      }

      // Reordenar las sesiones por la fecha del último mensaje
      store.commit('REORDER_SESSIONS');
    }
  });

  // Escuchar el evento `session.created` y agregar la nueva sesión
  window.Echo.channel('session-created').listen('.session.created', (data) => {
    const nuevaSesion = data.session;

    // Comprobar si ya existe para evitar duplicados
    const existe = sessiones.value.find((s) => s.id === nuevaSesion.id);
    if (!existe) {
      store.commit("ADD_SESSION", nuevaSesion);
    }
  });

  // Escuchar el evento `TomarSession` para eliminar el badge de la sesión correspondiente
  window.Echo.channel('tomarsession').listen('.tomarsession', (data) => {
    const { session } = data;
    console.log('session tomada')
    // Buscar la sesión en el store
    const existingSession = store.state.sessiones.find((s) => s.id === session.id);

    if (existingSession) {
      // Actualizar el contador de nuevos mensajes a 0 para quitar el badge verde
      existingSession.nuevos_mensajes = 0;

      // Reordenar las sesiones si es necesario
      store.commit('REORDER_SESSIONS');
    }
  });
});



</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
