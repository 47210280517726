import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import axios from "axios";
import Echo from "laravel-echo";

loadFonts();

// Configurar Axios con la URL de la API desde el archivo .env
axios.defaults.baseURL = process.env.VUE_APP_API_URL ?? "http://localhost";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

import Pusher from "pusher-js";
window.Pusher = Pusher;

// Configurar Echo utilizando las variables de entorno del .env
window.Echo = new Echo({
  broadcaster: "reverb",  // Asegúrate de que broadcaster sea 'pusher' o 'reverb', según tu configuración
  key: process.env.VUE_APP_REVERB_KEY,  // Asegúrate de que la clave esté definida en el .env
  wsHost: process.env.VUE_APP_REVERB_HOST ?? window.location.hostname,
  wsPort: process.env.VUE_APP_REVERB_SCHEME === 'https' ? 443 : 8080,
  wssPort: process.env.VUE_APP_REVERB_SCHEME === 'https' ? 443 : null,
  forceTLS: process.env.VUE_APP_REVERB_SCHEME === 'https',
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
});



// Realizar solicitud para obtener la cookie CSRF
try {
  await axios.get("/sanctum/csrf-cookie");
  await store.dispatch("getUser");
} catch (e) {
  console.error(e);
}

// Montar la aplicación Vue
createApp(App).use(router).use(store).use(vuetify).mount("#app");
