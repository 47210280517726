<!-- SessionChat.vue -->
<template>
  <v-col cols="12">
    <v-card flat class="ma-0">
      <!-- Header del Chat -->
      <v-toolbar color="indigo-darken-3" class="mb-5" flat>
        <v-avatar class="ml-4" color="grey-lighten-1">
          <v-icon color="white" size="large">mdi-account</v-icon>
        </v-avatar>
        <v-toolbar-title v-if="phonenumber">{{ phonenumber.phone_number }}</v-toolbar-title>
        <v-spacer></v-spacer>
        {{sessionId  }}
        <!-- Estado del Chat -->
        <div class="d-flex align-center mr-4">
          <!-- Estado General -->
          <template v-if="session && session.estado">
            <v-tooltip :text="session.estado.id === 1 ? 'ABIERTO' : 'CERRADO'" location="top">
              <template v-slot:activator="{ props }">
                <v-icon :color="session.estado.id === 1 ? 'white' : session.estado.id === 2 ? 'green' : 'grey'"
                  :icon="session.estado.id === 2 ? 'mdi-lock' : 'mdi-lock-open-variant'" @click="cambiarEstado"
                  v-bind="props" class="mx-2"></v-icon>
              </template>
            </v-tooltip>
          </template>

          <!-- Estado Callcenter -->
          <template v-if="session && session.estado_callcenter">
            <v-tooltip :text="session.estado_callcenter.id === 1 ? 'PENDIENTE' : 'ATENDIDO'" location="top">
              <template v-slot:activator="{ props }">
                <v-icon
                  :color="session.estado_callcenter.id === 1 ? 'white' : session.estado_callcenter.id === 2 ? 'green' : 'grey'"
                  @click="cambiarEstadoCallcenter" v-bind="props">
                  mdi-check-decagram
                </v-icon>
              </template>
            </v-tooltip>
          </template>

          <!-- Pin del Chat -->
          <v-tooltip :text="isPinned ? 'ANCLADO' : 'SIN ANCLAR'" location="top">
            <template v-slot:activator="{ props }">
              <v-btn icon :color="isPinned ? 'green' : 'white'" @click="pinChat" v-bind="props">
                <v-icon>{{ isPinned ? 'mdi-pin' : 'mdi-pin-outline' }}</v-icon>
              </v-btn>
            </template>
          </v-tooltip>
        </div>
      </v-toolbar>

      <!-- Lista de Mensajes -->
      <v-card-text>
        <div ref="messageContainer" class="chat-container">
          <v-list>
            <template v-for="(item, index) in groupedMessages" :key="index">
              <!-- Fecha Agrupada -->
              <v-row v-if="item.isDate" justify="center" align="center" class="date-row">
                <v-chip class="my-2 chip-limit" color="blue-grey lighten-4" label>{{ item.date }}</v-chip>
              </v-row>

              <!-- Mensaje -->
              <v-list-item v-else>
                <v-sheet :class="[
                  item.message.tipo_envio_mensaje_id === 1 ? 'd-flex align-end flex-column' : 'd-flex align-start flex-column'
                ]">
                  <v-sheet :color="getMessageColor(item.message.tipo_envio_mensaje_id)" class="message-bubble"
                    elevation="1">
                    <!-- Mostrar mensaje al que se está respondiendo -->
                    <div v-if="item.message.reply_to" class="reply-to">
                      <v-sheet class="reply-to-sheet">
                        <div class="reply-to-content">
                          <div class="d-flex align-center">
                            <v-icon small class="mr-1">
                              {{ getIconForMessageType(findReplyMessage(item.message.reply_to)?.tipo_mensaje_id || 1) }}
                            </v-icon>
                            <span>
                              {{ findReplyMessage(item.message.reply_to)?.mensaje || 'Mensaje no encontrado' }}
                            </span>
                          </div>
                        </div>
                      </v-sheet>
                    </div>

                    <!-- Contenido del mensaje -->
                    <div class="message-content">
                      <!-- Texto del mensaje -->
                      <div v-if="item.message.tipo_mensaje_id === 1" class="message-content">
                        <span>{{ item.message.mensaje }}</span>
                        <small class="timestamp">{{ formatTimestamp(item.message.created_at) }}</small>
                      </div>

                      <!-- Imagen del Mensaje -->
                      <div v-else-if="item.message.tipo_mensaje_id === 2" class="message-content">
                        <v-img :src="getFullUrl(item.message.mensaje)" :aspect-ratio="1" width="300" contain
                          @click="openImage(item.message.mensaje)" class="mb-2" style="cursor: pointer;" />
                        <small class="timestamp">{{ formatTimestamp(item.message.created_at) }}</small>
                      </div>
                      <!-- Mostrar sticker -->
                      <div v-if="item.message.tipo_mensaje_id === 3" class="message-content">
                        <v-img :src="getFullUrl(item.message.mensaje)" :aspect-ratio="1" width="150" cover />
                        <small class="timestamp">{{ formatTimestamp(item.message.created_at) }}</small>
                      </div>
                      <!-- Mostrar archivo -->
                      <div v-if="item.message.tipo_mensaje_id === 4" class="message-content">
                        <a :href="getFullUrl(item.message.mensaje)" target="_blank">
                          Descargar archivo
                        </a>
                        <small class="timestamp">{{ formatTimestamp(item.message.created_at) }}</small>
                      </div>
                      <!-- Mostrar video -->
                      <div v-if="item.message.tipo_mensaje_id === 5" class="message-content">
                        <video :src="getFullUrl(item.message.mensaje)" controls height="200" width="300" />
                        <small class="timestamp">{{ formatTimestamp(item.message.created_at) }}</small>
                      </div>
                      <!-- Mostrar audio -->
                      <div v-if="item.message.tipo_mensaje_id === 6" class="message-content">
                        <audio :src="getFullUrl(item.message.mensaje)" controls />
                        <small class="timestamp">{{ formatTimestamp(item.message.created_at) }}</small>
                      </div>
                      <!-- Mostrar PDF con primera página como previsualización -->
                      <div v-if="item.message.tipo_mensaje_id === 7" class="message-content">
                        <v-img :src="getPdfPreviewUrl(item.message.mensaje)" :aspect-ratio="1.41" width="300" contain />
                        <small class="timestamp">{{ formatTimestamp(item.message.created_at) }}</small>
                        <a :href="getFullUrl(item.message.mensaje)" target="_blank">Ver PDF Completo</a>
                      </div>
                      <v-menu activator="parent" offset-y>
                        <template v-slot:activator="{ props }">
                          <v-btn class="menu-icon" variant="text" v-bind="props" icon="mdi-dots-vertical">
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="replyMensaje(item.message)">
                            <v-list-item-title>Responder</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                    <!-- Botón de menú dentro de la burbuja -->

                  </v-sheet>
                </v-sheet>
              </v-list-item>
            </template>
          </v-list>
        </div>
      </v-card-text>

      <!-- Campo de Entrada y Botones -->
      <v-row no-gutters align="center" class="mt-4" style="position: relative;">
        <template v-if="session && session.estado">
          <!-- Vista previa de respuesta -->
          <v-row v-if="replyToMessage" class="reply-preview">
            <v-sheet class="reply-sheet">
              <div class="d-flex justify-space-between align-center">
                <div class="reply-content">
                  <span>Respondiendo a</span>
                  <div class="d-flex align-center">
                    <v-icon small class="mr-1">{{ getIconForMessageType(replyToMessage.tipo_mensaje_id) }}</v-icon>
                    <span>{{ getTextForMessageType(replyToMessage) }}</span>
                  </div>
                </div>
                <v-btn variant="text" icon="mdi-close" @click="replyToMessage = null">
                </v-btn>
              </div>
            </v-sheet>
          </v-row>

          <!-- Menú de Emojis -->
          <v-menu v-model="emojiMenuVisible" max-height="400" max-width="400">
            <template v-slot:activator="{ props }">
              <v-btn icon="mdi-emoticon-outline" v-bind="props" variant="text" :disabled="isSessionClosed"></v-btn>
            </template>
            <v-card>
              <v-container>
                <v-row dense>
                  <v-col v-for="(emoji, index) in emojis" :key="index" cols="3" class="text-center pa-1">
                    <v-btn text @click="agregarEmoji(emoji)" :disabled="isSessionClosed">
                      {{ emoji }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-menu>

          <!-- Botón para Adjuntar Archivos -->
          <v-btn icon="mdi-paperclip" @click="openFileDialog" :disabled="isSessionClosed" variant="text"></v-btn>

          <!-- Diálogo para Adjuntar Archivos -->
          <v-dialog v-model="dialogAttach" width="500">
            <v-card>
              <v-card-title class="headline">Adjuntar Archivos</v-card-title>
              <v-card-text>
                <v-file-input v-model="selectedAttachFile" label="Seleccione un archivo"
                  prepend-icon="mdi-paperclip"></v-file-input>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogAttach = false">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="attachFile">Adjuntar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Campo de Texto para Mensajes con Sugerencias de Mensajes Rápidos -->
          <div style="flex: 1; position: relative;">
            <!-- Campo de Texto -->
            <v-text-field
              ref="messageInput"
              v-model="mensaje"
              placeholder="Escribe un mensaje"
              flat
              append-icon="mdi-send"
              class="mx-4"
              @click:append="enviarMensaje"
              @keydown.enter.prevent="enviarMensaje"
              :disabled="isSessionClosed"
            ></v-text-field>

            <!-- Sugerencias de Mensajes Rápidos como Overlay -->
            <v-menu
              v-model="quickMessagesMenuVisible"
              :activator="messageInputActivator"
              transition="scale-transition"
              offset-y
              max-width="100%"
              min-width="300px"
            >
              <v-list dense>
                <v-list-item
                  v-for="mensajeRapido in filteredMensajesRapidos"
                  :key="mensajeRapido.id"
                  @click="selectQuickMessage(mensajeRapido)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="truncate" v-text="mensajeRapido.titulo"></v-list-item-title>
                    <v-list-item-subtitle class="truncate" v-text="mensajeRapido.descripcion"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-row>
      <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" />
    </v-card>

    <!-- Modal para Ver Imagen -->
    <v-dialog v-model="dialogImage" fullscreen transition="dialog-bottom-transition">
      <v-card class="mt-0">
        <v-toolbar flat color="indigo-darken-3">
          <v-btn icon @click="dialogImage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Imagen</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-img :src="getFullUrl(selectedImage)" height="90vh" width="90vw"></v-img>
      </v-card>
    </v-dialog>

    <!-- Modal para Previsualizar Archivo Adjunto -->
    <v-dialog v-model="dialogFile" fullscreen transition="dialog-bottom-transition">
      <v-card class="mt-0">
        <v-toolbar flat>
          <v-btn icon @click="dialogFile = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Previsualización del Archivo</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <template v-if="selectedFile">
            <v-container class="d-flex justify-center align-center">
              <!-- Imagen -->
              <div v-if="isImageFile(selectedFile.type)">
                <v-img :src="filePreview" max-height="70vh" contain></v-img>
              </div>
              <!-- Video -->
              <div v-else-if="isVideoFile(selectedFile.type)">
                <video :src="filePreview" controls height="600"></video>
              </div>
              <!-- Audio -->
              <div v-else-if="isAudioFile(selectedFile.type)">
                <audio :src="filePreview" controls></audio>
              </div>
              <!-- PDF -->
              <div v-else-if="isPdfFile(selectedFile.type)">
                <v-icon size="48" color="grey">mdi-file-document-outline</v-icon>
                <p class="file-name">{{ selectedFile.name }}</p>
                <p>No hay previsualización disponible</p>
              </div>
              <!-- Otros Archivos -->
              <div v-else class="no-preview-available">
                <v-icon size="48" color="grey">mdi-file-document-outline</v-icon>
                <p class="file-name">{{ selectedFile.name }}</p>
                <p>No hay previsualización disponible</p>
              </div>
            </v-container>
      
            <v-text-field v-model="mensaje" placeholder="Escribe un mensaje" flat class="my-4"
              @click:append="enviarArchivo" append-icon="mdi-send"></v-text-field>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script setup>
import {
  ref,
  computed,
  nextTick,
  onMounted,
  defineExpose,
  defineProps,
  watch,
} from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  sessionId: {
    type: [String, Number],
    required: true,
  },
});

const store = useStore();

const mensaje = ref('');
const replyToMessage = ref(null);
const emojiMenuVisible = ref(false);
const dialogImage = ref(false);
const selectedImage = ref('');
const dialogFile = ref(false);
const selectedFile = ref(null);
const filePreview = ref('');
const fileInput = ref(null);
const isPinned = ref(false);
const dialogAttach = ref(false);
const selectedAttachFile = ref(null);
let channel = null; 

// Variables para las sugerencias de mensajes rápidos
const quickMessagesMenuVisible = ref(false);
// Reemplazamos quickMessagesActivator por messageInputActivator
const messageInput = ref(null);
const messageInputActivator = computed(() => messageInput.value?.$el?.querySelector('input'));

const emojis = [
  '😀', '😁', '😂', '🤣', '😊', '😍', '😎', '🤩', '🤔', '😢',
  '😡', '👍', '🙏', '👀', '🤝', '✨', '🤒', '😷', '👋🏻',
  '🚑', '🚨', '📲', '💰', '💊', '🩺', '🌡️', '🩸', '🩻',
  '🧬', '🔬', '📩', '📅', '📝', '☢️', '🕐', '☠️',
  '✍🏻', '👩🏻‍⚕️', '🏥'
];

const phonenumber = computed(() => store.state.phonenumber.phonenumber);
const session = computed(() => store.state.phonenumber.session);
const isSessionClosed = computed(() => session.value?.estado?.id === 2);

const messageContainer = ref(null);

const getFullUrl = (fileName) => {
  return `${process.env.VUE_APP_API_URL}/api/file/${fileName}`;
};

const openImage = (imageName) => {
  selectedImage.value = imageName;
  dialogImage.value = true;
};

const openFileDialog = () => {
  dialogAttach.value = true;
};

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedFile.value = file;
    filePreview.value = URL.createObjectURL(file);
    dialogFile.value = true;
  }
};

const cambiarEstado = async () => {
  try {
    await store.dispatch("cambiarEstado", props.sessionId);
  } catch (error) {
    console.error("Error al cambiar el estado:", error);
  }
};

const cambiarEstadoCallcenter = async () => {
  try {
    await store.dispatch("cambiarEstadoCallcenter", props.sessionId);
  } catch (error) {
    console.error("Error al cambiar el estado del Callcenter:", error);
  }
};

const enviarArchivo = async () => {
  if (selectedFile.value) {
    const formData = new FormData();
    formData.append('file', selectedFile.value);
    formData.append('phonenumberId', phonenumber.value.id);
    formData.append('mensaje', mensaje.value);

    try {
      await store.dispatch('enviarArchivo', formData);
      dialogFile.value = false;
      selectedFile.value = null;
      filePreview.value = '';
      mensaje.value = '';
      await fetchMessages();
    } catch (error) {
      console.error('Error al enviar el archivo:', error);
    }
  }
};

const attachFile = async () => {
  if (selectedAttachFile.value) {
    const formData = new FormData();
    formData.append('file', selectedAttachFile.value);
    formData.append('session_id', props.sessionId);

    try {
      await store.dispatch('adjuntarArchivo', formData);
      dialogAttach.value = false;
      selectedAttachFile.value = null;
      await fetchMessages();
    } catch (error) {
      console.error('Error al adjuntar el archivo:', error);
    }
  }
};

const formatFechaLarga = (dateString) => {
  const date = new Date(dateString);
  const dia = date.getDate();
  const mes = date.toLocaleString('es-ES', { month: 'long' });
  const año = date.getFullYear();
  return `${dia} de ${mes} del ${año}`;
};

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const groupedMessages = computed(() => {
  const messages = phonenumber.value?.mensajes || [];
  let lastDate = null;

  return messages.reduce((acc, message) => {
    const currentDate = new Date(message.created_at).toLocaleDateString();
    if (lastDate !== currentDate) {
      acc.push({ isDate: true, date: formatFechaLarga(message.created_at) });
      lastDate = currentDate;
    }
    acc.push({ isDate: false, message });
    return acc;
  }, []);
});

const agregarEmoji = (emoji) => {
  mensaje.value += emoji;
  emojiMenuVisible.value = false;
};

const replyMensaje = (message) => {
  replyToMessage.value = message;
};

// Encuentra el mensaje original al que se responde
const findReplyMessage = (replyToId) => {
  const replyMessage = phonenumber.value?.mensajes.find(msg => msg.id === replyToId);
  return replyMessage || null;
};

const enviarMensaje = async () => {
  if (mensaje.value.trim() !== '') {
    const tempMessage = {
      id: Date.now(),
      mensaje: mensaje.value,
      tipo_envio_mensaje_id: 1,
      tipo_mensaje_id: 1,
      created_at: new Date(),
      status: 'sending',
      reply_to: replyToMessage.value ? replyToMessage.value.id : null,
    };

    phonenumber.value.mensajes.push(tempMessage);

    await nextTick();
    scrollToLastMessage();

    const mensajeEnviado = mensaje.value;
    mensaje.value = '';
    quickMessagesMenuVisible.value = false; // Ocultar sugerencias al enviar
    const reply_to_id = replyToMessage.value ? replyToMessage.value.id : null;
    replyToMessage.value = null;

    try {
      store.dispatch('enviarMensaje', {
        sessionId: props.sessionId,
        mensaje: mensajeEnviado,
        replyToId: reply_to_id,
      });
      const session = store.state.sessiones.find((s) => s.id === props.sessionId);
      if (session) {
        session.latest_mensaje = tempMessage;
        session.mensajes_max_created_at = tempMessage.created_at;
        store.commit('REORDER_SESSIONS');
      }
      const index = phonenumber.value.mensajes.findIndex((msg) => msg.id === tempMessage.id);
      if (index !== -1) phonenumber.value.mensajes[index].status = 'sent';
    } catch (error) {
      console.error('Error al enviar el mensaje:', error);
      const index = phonenumber.value.mensajes.findIndex((msg) => msg.id === tempMessage.id);
      if (index !== -1) phonenumber.value.mensajes[index].status = 'error';
    }
  }
};

const scrollToLastMessage = () => {
  if (messageContainer.value) {
    setTimeout(() => {
      messageContainer.value.scrollTop = messageContainer.value.scrollHeight;
    }, 100);
  }
};

const fetchMessages = async () => {
  await store.dispatch('fetchPhoneNumber', props.sessionId)
  checkIfPinned()
  await nextTick()
  scrollToLastMessage()
}

defineExpose({ fetchMessages, scrollToLastMessage })

const pinChat = async () => {
  try {
    await store.dispatch('pinSession', props.sessionId);
    isPinned.value = !isPinned.value;
  } catch (error) {
    console.error('Error al anclar el chat:', error);
  }
};

const checkIfPinned = () => {
  isPinned.value = session.value?.pinned === 1;
};

const isImageFile = (type) => type.includes('image');
const isVideoFile = (type) => type.includes('video');
const isAudioFile = (type) => type.includes('audio');
const isPdfFile = (type) => type.includes('pdf');

const getMessageColor = (tipo_envio_mensaje_id) => {
  return tipo_envio_mensaje_id === 2 ? 'blue-grey-darken-3' : 'green-darken-2';
};

// Función para obtener el icono según el tipo de mensaje
const getIconForMessageType = (tipo_mensaje_id) => {
  switch (tipo_mensaje_id) {
    case 1:
      return 'mdi-text';
    case 2:
      return 'mdi-image';
    case 3:
      return 'mdi-sticker-emoji';
    case 4:
      return 'mdi-file';
    case 5:
      return 'mdi-video';
    case 6:
      return 'mdi-music';
    case 7:
      return 'mdi-file-pdf';
    default:
      return 'mdi-file';
  }
};

// Función para obtener el texto según el tipo de mensaje
const getTextForMessageType = (message) => {
  switch (message.tipo_mensaje_id) {
    case 1:
      return message.mensaje.length > 30 ? message.mensaje.substring(0, 27) + '...' : message.mensaje;
    case 2:
      return 'Imagen';
    case 3:
      return 'Sticker';
    case 4:
      return 'Archivo';
    case 5:
      return 'Video';
    case 6:
      return 'Audio';
    case 7:
      return 'PDF';
    default:
      return 'Mensaje';
  }
};

// Obtener los mensajes rápidos desde Vuex
const mensajesRapidos = computed(() => store.state.mensajesRapidos.mensajesRapidosChat || []);

// Computed para filtrar los mensajes rápidos según lo que se escribe
const filteredMensajesRapidos = computed(() => {
  if (!mensajesRapidos.value || mensajesRapidos.value.length === 0) {
    return [];
  }
  return mensajesRapidos.value
    .filter((mensajeRapido) =>
      mensajeRapido.titulo.toLowerCase().includes(mensaje.value.toLowerCase()) ||
      mensajeRapido.descripcion.toLowerCase().includes(mensaje.value.toLowerCase())
    )
    .slice(0, 5); // Limitar a 5 sugerencias
});

// Mostrar u ocultar el menú de sugerencias
watch(mensaje, (newValue) => {
  if (newValue.length > 0 && filteredMensajesRapidos.value.length > 0) {
    quickMessagesMenuVisible.value = true;
  } else {
    quickMessagesMenuVisible.value = false;
  }
});

// Método para seleccionar un mensaje rápido
const selectQuickMessage = (mensajeRapido) => {
  mensaje.value = mensajeRapido.descripcion;
  quickMessagesMenuVisible.value = false;
};
// Función para configurar la conexión de Laravel Echo
function configureEchoChannel(sessionId) {
  channel = window.Echo.channel(`chat.${sessionId}`)
    .listen('.chat.updated', (event) => {
      console.log('Mensaje recibido:', event.chat_id, event.message);
      fetchMessages();

    });
}


onMounted(async () => {
  await fetchMessages();
  await store.dispatch('mensajesRapidos/fetchMensajesRapidosChat');
  configureEchoChannel(props.sessionId);
});

watch(
  () => props.sessionId,
  async (newSessionId, oldSessionId) => {
    if (newSessionId !== oldSessionId) {
      // Cierra el canal anterior si existe
      if (channel) {
        channel.stopListening('.chat.updated');
        window.Echo.leaveChannel(`chat.${oldSessionId}`);
      }

      // Configura la nueva conexión
      await fetchMessages();
      configureEchoChannel(newSessionId);
    }
  }
);
</script>

<style>
.message-bubble {
  position: relative;
  white-space: normal;
  word-break: break-word;
  padding: 12px 20px;
  border-radius: 12px;
  max-width: 70%;
  color: white;
}

.menu-icon {
  position: absolute;
  top: 0px;
  right: -12px;
}

.message-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.message-content span {
  flex-grow: 1;
}

.timestamp {
  font-size: 0.73rem;
  color: rgba(255, 255, 255, 0.7);
  margin-left: 8px;
  align-self: flex-end;
}

.chat-container {
  height: 55vh;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: nowrap;
}

.no-preview-available {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: grey;
  padding: 20px;
}

.file-name {
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}

.chip-limit {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.date-row {
  margin: 0 auto;
  padding: 0;
}

/* Estilos para la funcionalidad de respuesta */
.reply-preview {
  margin-bottom: 8px;
  width: 100%;
}

.reply-sheet {
  width: 100%;
  padding: 8px;
  background-color: #04793e;
  border-left: 4px solid #3f51b5;
}

.reply-content {
  flex-grow: 1;
}

.reply-to {
  margin-bottom: 4px;
}

.reply-to-sheet {
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  border-left: 4px solid rgba(0, 0, 0, 0.3);
}

.reply-to-content {
  color: rgba(255, 255, 255, 0.9);
}

/* Estilos para las sugerencias de mensajes rápidos */
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
