<template>
  <v-card outlined class="mt-0">
    <v-card-title>LISTADO DE CAMPAÑAS</v-card-title>
    <v-card-text>
      <v-row class="align-center mb-4">
        <v-col cols="8">
          <v-file-input v-model="newImage" accept="image/*" label="Subir Imagen de Campaña"></v-file-input>
        </v-col>
        <v-col cols="4">
          <v-btn @click="addImage" color="green" :disabled="!newImage">Agregar Imagen</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="campanas.length">
        <v-col v-for="image in campanas" :key="image.id" class="d-flex child-flex" cols="2">
          <v-card class="mt-0">
            <v-img :src="`${apiUrl}/api/campana/showimage/${image.id}`" :width="300" aspect-ratio="16/9" cover></v-img>
            <v-card-title>
              {{ image.titulo }}
            </v-card-title>
            <v-card-subtitle>
              {{ image.descripcion }}
            </v-card-subtitle>
            <v-card-actions>
              <v-btn @click="confirmDelete(image.id)" color="red" variant="flat">Eliminar</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      
      <!-- Modal de confirmación de eliminación -->
      <v-dialog v-model="showConfirmDialog" max-width="500">
        <v-card>
          <v-card-title class="text-h6">Confirmar Eliminación</v-card-title>
          <v-card-text>¿Está seguro de que desea eliminar esta campaña?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="showConfirmDialog = false">Cancelar</v-btn>
            <v-btn color="red darken-1" text @click="removeImage">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

const apiUrl = process.env.VUE_APP_API_URL;

const store = useStore();
const newImage = ref(null);
const showConfirmDialog = ref(false); // Controlar la visibilidad del modal
const selectedImageId = ref(null); // Guardar el ID de la imagen a eliminar

const campanas = computed(() => store.state.campanas);

const addImage = () => {
  if (newImage.value) {
    store.dispatch('agregarCampana', newImage.value).then(() => {
      store.dispatch('fetchCampana');
      newImage.value = null;
    });
  }
};

// Función para mostrar el modal de confirmación
const confirmDelete = (imageId) => {
  selectedImageId.value = imageId;
  showConfirmDialog.value = true;
};

// Función para eliminar la imagen después de la confirmación
const removeImage = () => {
  store.dispatch('eliminarCampana', selectedImageId.value).then(() => {
    store.dispatch('fetchCampana');
    showConfirmDialog.value = false; // Cerrar el modal
  });
};

onMounted(async () => {
  await store.dispatch('fetchCampana');
});
</script>
