<template>
  <v-row dense>

    <QuickMessages @scrollToBottom="scrollToBottomInChat" />
    <SessionChat ref="sessionChat" />
  </v-row>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';


import QuickMessages from './QuickMessages.vue';
import SessionChat from './SessionChat.vue';

const store = useStore();
const sessionChat = ref(null);

// Método para llamar a scrollToBottom en SessionChat
const scrollToBottomInChat = () => {
  if (sessionChat.value) {
    sessionChat.value.scrollToBottom();
  }
};

onMounted(async () => {
  // Llamada para cargar los mensajes rápidos de WhatsApp
  await store.dispatch('fetchMensajeswhatsappRapidos');
});
</script>
