<template>
  <v-card outlined class="mt-0">
    <v-card-title>Configuración de Atención</v-card-title>
    <v-card-text>
      <v-form @submit.prevent="guardarRangoDiasCita">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="rangoDiasCita"
              label="Rango de días para cita"
              type="number"
              min="1"
              hint="Ingresa el número de días de anticipación"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn type="submit" color="green" class="mt-4">Guardar Configuración</v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const rangoDiasCita = ref(0);

onMounted(async () => {
  await store.dispatch('parametros/fetchRangoDiasCita');
  rangoDiasCita.value = store.getters['parametros/rangoDiasCita'];
});

const guardarRangoDiasCita = async () => {
  try {
    await store.dispatch('parametros/storeRangoDiasCita', rangoDiasCita.value);
    console.log('Rango de días para cita guardado exitosamente');
  } catch (error) {
    console.error('Error guardando el rango de días para cita:', error);
  }
};
</script>

<style scoped>
/* Puedes agregar estilos personalizados aquí si lo necesitas */
</style>
