import { createRouter, createWebHashHistory } from "vue-router";
import store from "../store";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import AppContainer from "@/AppContainer.vue";
import DetalleSession from "@/components/DetalleSession.vue";
import ListadoSessionesView from "@/views/ListadoSessionesView.vue";
import AjustesView from "@/views/AjustesView.vue";
import CampanasView from "@/views/CampanasView.vue";
import PaquetesView from "@/views/PaquetesView.vue";
import DashboardView from "@/views/DashboardView.vue";
import MensajesRapidosView from "@/views/MensajesRapidosView.vue";
import ParametrosView from "@/views/ParametrosView.vue";

const routes = [
  {
    path: "/login",
    component: LoginView,
    name: "login",
  },
  {
    path: "/register",
    component: RegisterView,
    name: "register",
  },
  {
    path: "/",
    component: AppContainer,
    meta: { requiresAuth: true },
  },
  {
    path: "/dashboard",
    component: AppContainer,
    children: [
      {
        path: "",
        component:DashboardView,
        name: "dashboard",
        meta: { requiresAuth: true },
      }
    ],
  },
  {
    path: "/chats",
    component: AppContainer,
    children: [
      {
        path: "",
        component: ListadoSessionesView,
        name: "session",
        meta: { requiresAuth: true },
      },
      {
        path: "/session/:id",
        component: DetalleSession,
        name: "detalle_session",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/ajustes",
    component: AppContainer,
    children: [
      {
        path: "",
        component: AjustesView,
        name: "ajustes",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/campanas",
    component: AppContainer,
    children: [
      {
        path: "",
        component: CampanasView,
        name: "campanas",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/paquetesmaternos",
    component: AppContainer,
    children: [
      {
        path: "",
        component: PaquetesView,
        name: "paquetesmaternos",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/mensajesrapidos",
    component: AppContainer,
    children: [
      {
        path: "",
        component: MensajesRapidosView,
        name: "mensajesrapidos",
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/parametros",
    component: AppContainer,
    children: [
      {
        path: "",
        component: ParametrosView,
        name: "parametros",
        meta: { requiresAuth: true },
      },
    ],
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters.isAuthenticated && to.name === "login") {
    next("/");
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next("/login");
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});


export default router;
