<template>
    <v-card outlined class="mt-4">
      <v-card-title>Sesiones</v-card-title>
      <v-card-subtitle>Por Estado</v-card-subtitle>
      <v-card-text class="text-center">
        <div id="chartdiv_estado"></div>
      </v-card-text>
    </v-card>
  </template>
  
  <script>
  import * as am5 from "@amcharts/amcharts5";
  import * as am5percent from "@amcharts/amcharts5/percent";
  import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
  import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
  import { computed, onMounted, onUnmounted, watch } from "vue";
  import { useStore } from "vuex";
  
  export default {
    setup() {
      const store = useStore();
  
      // Llamada a la acción para cargar los datos de sesiones por estado
      store.dispatch("dashboard/fetchSesionesPorEstado");
  
      // Obtener los datos de sesiones por estado desde Vuex
      const sesionesPorEstado = computed(() => store.getters["dashboard/getSesionesPorEstado"]);
  
      let rootEstado, seriesEstado;
  
      const createChartEstado = () => {
        if (rootEstado) {
          rootEstado.dispose();
        }
  
        rootEstado = am5.Root.new("chartdiv_estado");
  
        // Aplicar temas
        rootEstado.setThemes([am5themes_Animated.new(rootEstado), am5themes_Responsive.new(rootEstado)]);
  
        // Crear el gráfico de pastel
        const chart = rootEstado.container.children.push(
          am5percent.PieChart.new(rootEstado, {
            endAngle: 270,
          })
        );
  
        seriesEstado = chart.series.push(
          am5percent.PieSeries.new(rootEstado, {
            valueField: "value",
            categoryField: "category",
            endAngle: 270,
            tooltip: am5.Tooltip.new(rootEstado, {
              labelText: "{category}: {value}",
            }),
          })
        );
  
        seriesEstado.states.create("hidden", {
          endAngle: -90,
        });
  
        updateChartDataEstado();
      };
  
      // Actualizar los datos del gráfico de pastel
      const updateChartDataEstado = () => {
        if (seriesEstado && sesionesPorEstado.value.labels_estado.length > 0) {
          const data = sesionesPorEstado.value.labels_estado.map((category, index) => ({
            category: category,
            value: sesionesPorEstado.value.value[index],
          }));
  
          seriesEstado.data.setAll(data);
        }
      };
  
      // Observar los datos de sesionesPorEstado y crear el gráfico cuando estén disponibles
      watch(sesionesPorEstado, (newValue) => {
        if (newValue.labels_estado.length > 0) {
          createChartEstado();
        }
      });
  
      onMounted(() => {
        // Los gráficos se crearán cuando los datos estén disponibles
      });
  
      // Limpiar el gráfico cuando se desmonte el componente
      onUnmounted(() => {
        if (rootEstado) {
          rootEstado.dispose();
        }
      });
  
      return { sesionesPorEstado };
    },
  };
  </script>
  
  <style scoped>
  #chartdiv_estado {
    width: 100%;
    height: 500px;
  }
  </style>
  